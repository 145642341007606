import Icon from '../components/Icon';

const Beach = Icon(
  'beach',
  <>
    <path d="M16.642 3.854c-2.803 0-4.424 1.785-5.936 3.45q-.28.31-.558.61c-.878.938-1.8 1.824-2.989 2.482-1.178.652-2.655 1.1-4.659 1.1a.75.75 0 1 0 0 1.5c2.246 0 3.975-.507 5.385-1.288 1.4-.774 2.451-1.8 3.36-2.77q.295-.318.57-.619c1.57-1.709 2.724-2.965 4.827-2.965 1.187 0 2.006.62 2.53 1.246a3.55 3.55 0 0 0-1.922 3.15 3.5 3.5 0 0 0 3.5 3.5h.75a.75.75 0 0 0 0-1.5h-.75a2 2 0 0 1-2-2c0-1.014.75-1.867 1.705-2.008l.355-.052a.486.486 0 0 0 .354-.728c-1.01-1.686-2.315-3.108-4.522-3.108M2.5 16.75c.605 0 1.078.176 1.452.482.33.27.544.615.688.85l.015.023c.168.272.261.412.376.508.08.067.199.137.469.137.498 0 .75-.239 1.156-.728l.078-.095c.364-.448.957-1.177 2.016-1.177 1.061 0 1.65.732 2.011 1.18l.077.094c.401.489.65.726 1.146.726.5 0 .757-.241 1.166-.731l.079-.095c.368-.448.964-1.174 2.021-1.174 1.059 0 1.652.729 2.016 1.177l.078.095c.405.489.658.728 1.156.728.27 0 .388-.07.466-.136.114-.096.206-.235.374-.508l.014-.022c.145-.235.358-.581.69-.852.375-.306.85-.482 1.456-.482a.75.75 0 0 1 0 1.5c-.291 0-.423.075-.507.143-.117.096-.212.235-.376.5l-.023.038c-.14.229-.348.566-.661.83-.367.31-.834.49-1.433.49-1.24 0-1.888-.762-2.311-1.272-.472-.57-.628-.729-.939-.729-.313 0-.473.162-.949.731-.427.51-1.079 1.27-2.317 1.27-1.242 0-1.886-.764-2.305-1.275-.468-.57-.62-.726-.929-.726s-.467.159-.939.729c-.423.51-1.07 1.271-2.311 1.271-.599 0-1.066-.18-1.433-.488-.315-.264-.524-.602-.665-.831l-.022-.036c-.165-.266-.26-.406-.378-.502-.084-.069-.215-.143-.502-.143a.75.75 0 0 1 0-1.5" />
  </>
);

export default Beach;
