import Icon from '../components/Icon';

const HotSpring = Icon(
  'hot-spring',
  <>
    <path d="M14.25 6.937c0-.595.155-1.226.507-1.738.147-.213.242-.532.242-.887s-.095-.673-.242-.886a.75.75 0 0 1 1.235-.852c.353.512.508 1.143.508 1.738s-.155 1.227-.508 1.739c-.147.213-.242.531-.242.886s.095.674.242.887a.75.75 0 0 1-1.235.852c-.352-.512-.507-1.144-.507-1.739" />
    <path
      fillRule="evenodd"
      d="M3.62 18.147a.75.75 0 0 1-.29-.312l-.05-.1a9.75 9.75 0 0 1-1.03-4.36V12.5c0-.777.637-1.5 1.464-1.5h16.572c.827 0 1.464.723 1.464 1.5v.875a9.75 9.75 0 0 1-1.03 4.36l-.05.1a.75.75 0 0 1-.29.312A3.7 3.7 0 0 1 21.714 21a.715.715 0 1 1-1.43 0 2.285 2.285 0 1 0-4.57 0 .715.715 0 1 1-1.43 0 2.285 2.285 0 0 0-4.57 0 .715.715 0 1 1-1.43 0 2.285 2.285 0 1 0-4.57 0 .715.715 0 1 1-1.43 0c0-1.146.52-2.172 1.336-2.853m16.63-5.642v.87a8.25 8.25 0 0 1-.871 3.69l-.05.1a.75.75 0 0 0-.08.335A3.71 3.71 0 0 0 15 18.809a3.71 3.71 0 0 0-2.999-1.523 3.71 3.71 0 0 0-3 1.524A3.71 3.71 0 0 0 4.75 17.5a.75.75 0 0 0-.08-.335l-.049-.1a8.25 8.25 0 0 1-.871-3.69v-.87l.003-.005h16.494z"
      clipRule="evenodd"
    />
    <path d="M11.757 5.2c-.352.51-.507 1.142-.507 1.737s.155 1.227.507 1.739a.75.75 0 1 0 1.235-.852c-.147-.213-.242-.532-.242-.887s.095-.673.242-.886c.353-.512.507-1.144.507-1.739s-.154-1.226-.507-1.738a.75.75 0 1 0-1.235.852c.147.213.242.531.242.886s-.095.674-.242.887m5.493 1.738c0-.595.155-1.226.507-1.738.147-.213.242-.532.242-.887s-.095-.673-.242-.886a.75.75 0 0 1 1.235-.852c.353.512.508 1.143.508 1.738s-.155 1.227-.508 1.739c-.147.213-.242.531-.242.886s.095.674.242.887a.75.75 0 0 1-1.235.852c-.352-.512-.507-1.144-.507-1.739" />
  </>
);

export default HotSpring;
