import Icon from '../components/Icon';

const BirdWatching = Icon(
  'bird-watching',
  <>
    <path d="m22 7.84-1.55-1.16a.82.82 0 0 0-.25-.12A4 4 0 0 0 16.34 3a3.87 3.87 0 0 0-3 1.44L1.42 18.77a.77.77 0 0 0-.1.8A.75.75 0 0 0 2 20h8.75v4h1.5v-4h.53a7.62 7.62 0 0 0 7.47-7.75v-1.94a.611.611 0 0 0 .21-.11L22 9a.74.74 0 0 0 .3-.6.75.75 0 0 0-.3-.56Zm-3.25 4.41a6.13 6.13 0 0 1-6 6.25H6l9.32-5.07a.74.74 0 0 0 .3-1 .76.76 0 0 0-1-.31L4.18 17.8 14.5 5.39a2.36 2.36 0 0 1 1.84-.89 2.48 2.48 0 0 1 2.41 2.55v5.2Z" />
  </>
);

export default BirdWatching;
