import Icon from '../components/Icon';

const Waterfall = Icon(
  'waterfall',
  <>
    <path d="M8.724 2.25A3.477 3.477 0 0 0 5.25 5.712V15a.75.75 0 0 0 1.5 0V5.718A1.977 1.977 0 0 1 8.724 3.75H21a.75.75 0 0 0 0-1.5z" />
    <path d="M10 5.25a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75M14.75 10.75a.75.75 0 0 0-1.5 0V15a.75.75 0 0 0 1.5 0zM6 18.75A2.25 2.25 0 0 0 3.75 21a.75.75 0 0 1-1.5 0A3.75 3.75 0 0 1 9 18.75a3.74 3.74 0 0 1 3-1.5c1.227 0 2.316.589 3 1.5A3.75 3.75 0 0 1 21.75 21a.75.75 0 0 1-1.5 0 2.25 2.25 0 0 0-4.5 0 .75.75 0 0 1-1.5 0 2.25 2.25 0 0 0-4.5 0 .75.75 0 0 1-1.5 0A2.25 2.25 0 0 0 6 18.75M18.75 7.5a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 0 0-1.5h-.5a2.25 2.25 0 0 0-2.25 2.25V15a.75.75 0 0 0 1.5 0z" />
  </>
);

export default Waterfall;
