import Icon from '../components/Icon';

const Wildlife = Icon(
  'wildlife',
  <>
    <path d="M21.816 4.733c-.347-.449-1.018-.983-2.222-.983-1.58 0-3.606 1.058-5.42 2.827a13 13 0 0 0-1.424 1.629V5.25a.75.75 0 1 0-1.5 0v2.956a13 13 0 0 0-1.42-1.628C8.015 4.807 5.989 3.75 4.406 3.75c-1.203 0-1.875.534-2.222.982-.639.823-1.141 2.258-.02 6.676.568 2.234 1.83 3.093 2.872 3.414A4.125 4.125 0 0 0 12 19.244a4.125 4.125 0 0 0 6.964-4.422c1.042-.319 2.303-1.18 2.871-3.414.63-2.48 1.12-5.211-.02-6.675M8.625 19.5a2.637 2.637 0 0 1-.294-5.25.75.75 0 1 0-.165-1.49 4.1 4.1 0 0 0-1.945.74c-.79.008-2.062-.335-2.602-2.462-.27-1.064-1.088-4.301-.25-5.385.134-.172.39-.403 1.037-.403 2.557 0 6.844 4.208 6.844 6.72v4.905A2.625 2.625 0 0 1 8.625 19.5m11.756-8.462c-.54 2.127-1.812 2.47-2.603 2.462a4.1 4.1 0 0 0-1.945-.745.75.75 0 1 0-.165 1.49 2.637 2.637 0 0 1 .133 5.223 2.635 2.635 0 0 1-3.051-2.593v-4.906c0-2.511 4.283-6.719 6.844-6.719.651 0 .905.23 1.04.403.84 1.084.017 4.321-.253 5.385" />
  </>
);

export default Wildlife;
