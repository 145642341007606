import Icon from '../components/Icon';

const River = Icon(
  'river',
  <>
    <path
      fillRule="evenodd"
      d="M12.684 3c.759.404 1.349 1.072 1.349 2.071 0 1.706-1.403 2.508-2.31 3.02-.517.291-.915.505-1.206.753-.144.123-.515.495-.238.644.19.102.53.195 1.049.26.818.1 1.855.107 2.993.115q.443.003.902.008c1.435.017 2.975.073 4.165.395.598.162 1.18.41 1.621.814.468.428.741 1 .741 1.698 0 .554-.159 1.038-.454 1.443-.287.395-.675.677-1.081.885-.793.407-1.805.606-2.697.758q-.267.046-.518.087c-.73.12-1.36.225-1.853.388-.153.051-.616.172-.616.434 0 .406.397.43.736.475.345.046.762.05 1.243.038q.232-.006.487-.014c.33-.01.678-.022 1.003-.022a.75.75 0 0 1 0 1.5c-.303 0-.604.01-.917.02q-.261.01-.538.016c-.49.011-1.007.011-1.475-.051-.458-.06-.966-.191-1.368-.514-.445-.356-.67-.87-.67-1.482 0-.515.22-.93.556-1.236.31-.282.706-.46 1.086-.587.616-.205 1.398-.334 2.132-.454q.235-.037.459-.076c.912-.156 1.705-.327 2.265-.614.27-.139.446-.285.553-.433.1-.138.167-.31.167-.56a.74.74 0 0 0-.254-.593c-.191-.175-.514-.34-1-.472-.98-.265-2.333-.325-3.791-.343q-.418-.005-.843-.007c-1.147-.008-2.304-.015-3.218-.128-.566-.07-1.132-.189-1.574-.426-.476-.255-.893-.704-.893-1.385 0-.778.407-1.33.866-1.721.431-.369.997-.668 1.443-.92 1.022-.577 1.547-.99 1.547-1.713 0-.286-.127-.52-.553-.746-.462-.245-1.173-.412-2.084-.508-1.322-.138-2.856-.111-4.302-.086-.55.01-1.086.019-1.594.019H3a.75.75 0 1 1 0-1.5h1c.45 0 .949-.009 1.474-.018 1.473-.026 3.158-.055 4.579.093.964.102 1.908.292 2.63.676"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M2.75 5.974a.75.75 0 0 1 .776-.724c.874.03 1.665.093 2.244.326.31.124.609.316.825.621.218.31.3.664.3 1.02 0 .704-.464 1.12-.84 1.358-.362.23-.764.386-1.147.578-.367.183-.65.365-.844.595-.176.208-.314.495-.314.966 0 .441.236.726.623.911.496.237 1.212.35 2.057.431q.28.027.579.05c.631.053 1.297.109 1.823.226.35.079.737.203 1.048.438.35.263.587.656.587 1.16 0 .51-.332.854-.57 1.044-.257.205-.587.378-.915.53a24 24 0 0 1-1.226.511c-.361.144-.731.291-1.1.45-.826.356-1.58.743-2.122 1.2-.53.45-.784.902-.784 1.408C3.75 20.5 7 20.5 9.428 20.5H21a.75.75 0 0 1 0 1.5H9.428c-2.015 0-7.178.159-7.178-2.927 0-1.102.586-1.936 1.315-2.552.719-.608 1.643-1.066 2.497-1.433.431-.186.862-.356 1.252-.512a22 22 0 0 0 1.04-.434c.206-.096.478-.18.477-.25-.003-.19-1.702-.27-1.852-.282q-.329-.026-.691-.06c-.835-.08-1.798-.207-2.562-.572-.394-.188-.772-.457-1.049-.847-.283-.399-.427-.876-.427-1.417 0-.814.256-1.447.669-1.935.395-.467.899-.759 1.318-.968.225-.113 1.126-.349 1.138-.665.017-.448-1.69-.39-1.901-.396a.75.75 0 0 1-.724-.776"
      clipRule="evenodd"
    />
  </>
);

export default River;
