import Icon from '../components/Icon';

const Cave = Icon(
  'cave',
  <>
    <path
      fillRule="evenodd"
      d="M8.5 3a.75.75 0 0 0-.685.445L5.87 7.818 3.47 10.22a.75.75 0 0 0-.216.451l-1 9.5A.75.75 0 0 0 3 21h18a.75.75 0 0 0 .743-.85l-1-7.5a.75.75 0 0 0-.157-.369l-1.892-2.364-.962-4.33a.75.75 0 0 0-.36-.488l-3.5-2A.75.75 0 0 0 13.5 3zm11.643 16.5h-4.705l-.44-5.065-.001-.004c-.065-.695-.284-1.46-.876-2.052a3 3 0 0 0-4.242 0c-.592.592-.811 1.357-.876 2.052l-.44 5.069h-4.73l.885-8.407L6.311 9.5H9A.75.75 0 0 0 9 8H7.432l1.555-3.5h4.314L15.926 6H14a.75.75 0 0 0 0 1.5h2.62l.648 2.913a.75.75 0 0 0 .146.306l1.87 2.337zm-10.075 0h3.864l-.429-4.93v-.003c-.047-.503-.19-.876-.442-1.128a1.5 1.5 0 0 0-2.122 0c-.251.252-.395.625-.442 1.128v.002z"
      clipRule="evenodd"
    />
  </>
);

export default Cave;
