import { ApiError, post } from '@alltrails/shared/api';
import logSignUpFailed from '@alltrails/analytics/events/logSignUpFailed';
import logLogInFailed from '@alltrails/analytics/events/logLogInFailed';
import { logError } from '@alltrails/core';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import { authSuccessCallback } from '../utils/AuthHandlers';
import LoginResponse from '../types/LoginResponse';

function getToken(googleUserOrToken: GoogleLoginResponse | GoogleLoginResponseOffline | string) {
  if (typeof googleUserOrToken === 'string') {
    return googleUserOrToken;
  }

  if ('getAuthResponse' in googleUserOrToken) {
    return googleUserOrToken.getAuthResponse().id_token;
  }

  return undefined;
}

export default function useGoogleHandler() {
  async function googleHandler(
    googleUserOrToken: GoogleLoginResponse | GoogleLoginResponseOffline | string,
    handlerType: 'login' | 'signup',
    referralLink?: string,
    oneTap?: boolean
  ) {
    const token = getToken(googleUserOrToken);

    let response: LoginResponse;

    try {
      response = await post<LoginResponse>('/identity/auth/google', {
        client_id: process.env.NEXT_PUBLIC_IDENTITY_CLIENT_ID,
        google_token: token,
        ...(referralLink && { referral_code: referralLink }),
        ...(oneTap && { one_tap: oneTap })
      });

      authSuccessCallback(response, oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google, response.is_collab_list_invite);
    } catch (e: any) {
      const failureType = e instanceof ApiError && e.data?.errors?.length > 0 ? e.data.errors[0].code : e.status;

      if (handlerType === 'signup') {
        logSignUpFailed({ authentication_type: oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google, failure_type: failureType });
      } else {
        logLogInFailed({
          authentication_type: oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google,
          failure_type: failureType
        });
      }

      logError(new Error(`There was an error logging in to or signing up with Google: ${e} as ${e.message}`));
      throw e;
    }

    return response;
  }

  return googleHandler;
}
