import Icon from '../components/Icon';

const Views = Icon(
  'views',
  <>
    <path
      fillRule="evenodd"
      d="M5.456 2.087a3.456 3.456 0 1 0 0 6.912 3.456 3.456 0 0 0 0-6.912M3.5 5.543a1.956 1.956 0 1 1 3.912 0 1.956 1.956 0 0 1-3.912 0"
      clipRule="evenodd"
    />
    <path d="M2.75 17a.75.75 0 0 0 0 1.5H21.5a.75.75 0 0 0 0-1.5zM7.25 20.75A.75.75 0 0 1 8 20h8.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75M3.558 15.5h-1.5c-.022-.792.179-1.619.5-2.338.504-1.126 1.535-2.36 3.529-2.641.98-.14 1.73-.438 2.348-.824.75-.466 1.354-1.108 1.946-1.754.926-1.01 1.981-2.163 3.93-2.163 1.04 0 2.011.474 2.86 1.152.853.682 1.641 1.615 2.336 2.65 1.047 1.557 2.395 3.703 2.45 5.918h-1.499c-.043-1.715-1.116-3.475-2.196-5.082-.642-.956-1.332-1.758-2.027-2.314-.7-.559-1.347-.824-1.924-.824-1.226 0-1.857.631-2.797 1.648-.674.73-1.36 1.449-2.202 1.99-.778.5-1.745.906-3.013 1.088-1.075.153-1.929.778-2.372 1.769a4.25 4.25 0 0 0-.37 1.725" />
  </>
);

export default Views;
