import Icon from '../components/Icon';

const HistoricSite = Icon(
  'historic-site',
  <>
    <path
      fillRule="evenodd"
      d="M4.19 2.44A1.5 1.5 0 0 1 5.25 2H7.5a.75.75 0 0 1 .75.75V5h1.5V2.75A.75.75 0 0 1 10.5 2h3a.75.75 0 0 1 .75.75V5h1.5V2.75A.75.75 0 0 1 16.5 2h2.25a1.5 1.5 0 0 1 1.5 1.5v5.19c0 .397-.158.778-.439 1.06a1 1 0 0 1-.093.078l-1.43 1.03.894 8.642h1.068a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1 0-1.5h1.068l.895-8.642-1.432-1.03a1 1 0 0 1-.092-.079A1.5 1.5 0 0 1 3.75 8.69V3.5c0-.398.158-.78.44-1.06m12.563 8.131.922 8.929h-2.28l-.398-4.565v-.004c-.065-.695-.284-1.46-.876-2.052a3 3 0 0 0-4.242 0c-.592.592-.811 1.357-.876 2.052L8.606 19.5H6.325l.922-8.929a.75.75 0 0 0-.31-.68L5.25 8.677V3.5h1.5v2.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V3.5h1.5v2.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V3.5h1.5v5.177l-1.688 1.214a.75.75 0 0 0-.309.68M10.111 19.5l.386-4.43v-.003c.047-.503.19-.876.442-1.128a1.5 1.5 0 0 1 2.122 0c.251.252.395.625.442 1.128v.002l.386 4.431z"
      clipRule="evenodd"
    />
  </>
);

export default HistoricSite;
