// Our custom AF OneLink configuration is documented here:
// https://alltrails.atlassian.net/wiki/spaces/~62c329f2ce5a604dbfb3d25e/pages/2453078033/AppsFlyer+OneLink+SmartScript+Configuration+WIP

import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { SessionAttribution } from '@alltrails/session-storage';

type AFOneLinkConfig = {
  apexDomain: string;
  deepLinkPath: string;
  displayTrigger?: CarouselDisplayTrigger;
  feature?: 'banner' | 'interstitial';
  browserName?: string;
  analyticsParams: URLSearchParams;
  sessionAttribution?: SessionAttribution;
};

/* 
  AppsFlyer provides af_sub[1-5] parameters for custom values that are added to the install event in Amplitude.

  This af_sub structure is relied on by the analytics & marketing teams, so double check with them before changing.
  - af_sub1: install event attributes structured as search params (e.g. type=install_prompt&cool_experiment=treatment_a)
  - af_sub2: page referrer
*/

const getAFOneLinkConfig = ({ deepLinkPath, analyticsParams, sessionAttribution, apexDomain }: AFOneLinkConfig) => {
  const { referring_domain: sessionReferrer, utm_source, utm_campaign, utm_content, utm_medium, utm_term } = sessionAttribution || {};
  const hostName = document.referrer ? new URL(document.referrer).hostname : '';
  const referrerDomain = !hostName.includes(apexDomain) ? hostName : undefined;

  return {
    oneLinkURL: 'https://alltrails.onelink.me/EFvD',
    afParameters: {
      mediaSource: { keys: ['utm_source'], defaultValue: utm_source || 'web_to_app' },
      campaign: { keys: ['utm_campaign'], defaultValue: utm_campaign },
      channel: { keys: ['utm_medium'], defaultValue: utm_medium },
      deepLinkValue: { keys: [], defaultValue: deepLinkPath },

      afSub1: { keys: [], defaultValue: encodeURIComponent(analyticsParams.toString()) },
      afSub2: { keys: [], defaultValue: sessionReferrer || referrerDomain },

      afCustom: [
        { paramKey: 'content', keys: ['utm_content'], defaultValue: utm_content },
        { paramKey: 'term', keys: ['utm_term'], defaultValue: utm_term }
      ]
    }
  };
};

export default getAFOneLinkConfig;
