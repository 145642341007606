/**
 * sessionStorage can only be used client-side and if the user's permissions do not block it
 *
 * @returns true if sessionStorage is accessible, false if not
 */
export const canUseSessionStorage = () => {
  if (typeof window !== 'undefined') {
    const test = 'alltrails-session-storage-test';
    try {
      window.sessionStorage?.setItem(test, test);
      window.sessionStorage?.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const getItem = <T>(key: string): T | null => {
  if (canUseSessionStorage()) {
    const value = window.sessionStorage?.getItem?.(key);
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value) as T;
    } catch (error) {
      // JSON.parse can fail for basic strings that were already put into storage before this hook was created
      return JSON.parse(JSON.stringify(value)) as T;
    }
  }
  return null;
};

export const setItem = <T>(key: string, value: T) => {
  if (canUseSessionStorage()) {
    window.sessionStorage?.setItem?.(key, JSON.stringify(value));
  }
};

export const removeItem = (key: string) => {
  if (canUseSessionStorage()) {
    window.sessionStorage?.removeItem?.(key);
  }
};
