import { useDispatch, useSelector } from 'react-redux';
import type Context from '@alltrails/core/types/Context';
import type User from '@alltrails/core/types/User';
import { setContext } from '../slices/contextSlice';

const useSetUser = () => {
  const dispatch = useDispatch();
  const currentContext = useSelector((state: { context: Context }) => state.context);

  return (user: User) => {
    dispatch(
      setContext({
        ...currentContext,
        currentUser: user,
        displayMetric: user.metric ?? currentContext.displayMetric,
        // TODO https://alltrails.atlassian.net/browse/PLAT-4565
        proUser: user.pro
      })
    );
  };
};

export default useSetUser;
