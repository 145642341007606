import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import type Experiments from '@alltrails/core/types/Experiments';

/* 
  Provides commonly used params for install event analytics.
*/

type Config = {
  experiments: Experiments;
  displayTrigger?: CarouselDisplayTrigger;
  feature?: 'banner' | 'interstitial';
  promptType?: string;
  browserName?: string;
};

const getAnalyticsParams = ({ promptType, browserName, displayTrigger, experiments }: Config) => {
  const params = new URLSearchParams();

  params.append('type', 'install_prompt');

  // Non-exhaustive list of experiments that should be included in the analytics events.
  const experimentKeys: Array<keyof Experiments> = ['web-growth-rm-app-install-on-auth-wall-mobile'];

  experimentKeys.forEach(key => {
    const value = experiments[key]?.value;

    if (value) {
      params.append(key, value);
    }
  });

  if (promptType) {
    params.append('feature', `native_${promptType}`);
  }

  if (browserName) {
    params.append('browser', browserName);
  }

  if (displayTrigger) {
    params.append('creative_name', displayTrigger);
  }

  return params;
};

export default getAnalyticsParams;
