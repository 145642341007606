import Icon from '../components/Icon';

const Wildflowers = Icon(
  'wildflowers',
  <>
    <path
      fillRule="evenodd"
      d="M21 4.25a.75.75 0 0 0-.75-.75c-1.983 0-3.699.995-4.638 1.934a8 8 0 0 0-.643.724 9 9 0 0 0-.98-1.335C13.502 4.275 12.808 3.5 12 3.5c-.79 0-1.516.792-1.988 1.323-.339.38-.683.837-.98 1.335a8 8 0 0 0-.644-.724C7.45 4.495 5.733 3.5 3.75 3.5a.75.75 0 0 0-.75.75c0 2.707.711 5.014 2.031 6.727 1.335 1.732 3.603 2.834 5.774 3.077q.223.023.445.042v4.07c-1.51-1.778-3.766-2.614-5.978-2.807a.75.75 0 1 0-.13 1.494c2.776.243 5.289 1.57 6.113 4.485.105.371.36.662.745.662.375 0 .644-.284.741-.634.816-2.934 3.336-4.27 6.121-4.513a.75.75 0 1 0-.13-1.494c-2.214.193-4.473 1.03-5.982 2.811v-4.074l.463-.044c2.275-.281 4.43-1.354 5.756-3.075C20.289 9.264 21 6.957 21 4.25m-16.475.821c1.072.2 2.171.793 2.802 1.424A7 7 0 0 1 8.46 7.993c.097.185.243.33.445.397a.76.76 0 0 0 .951-.483c.245-.73.75-1.496 1.275-2.087a5 5 0 0 1 .71-.675q.095-.07.158-.108.064.038.157.108c.2.15.45.38.71.675.529.593 1.035 1.364 1.279 2.096a.75.75 0 0 0 .948.474c.247-.082.373-.274.495-.487a7 7 0 0 1 1.084-1.408c.63-.63 1.73-1.224 2.802-1.424-.128 2.07-.74 3.752-1.695 4.99-1.201 1.56-3.257 2.376-5.178 2.542-.41.036-.794.036-1.204 0-1.921-.166-3.977-.982-5.178-2.542-.954-1.238-1.567-2.92-1.695-4.99"
      clipRule="evenodd"
    />
  </>
);

export default Wildflowers;
