import { useEffect, useState, useCallback } from 'react';
import useApexDomain from '@alltrails/context/hooks/useApexDomain';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useSelector } from 'react-redux';
import { useExperiments } from '@alltrails/experiments';
import { useSessionStorage } from '@alltrails/session-storage';
import { getAFOneLinkConfig } from '../utils/getAFOneLinkConfig';
import getAnalyticsParams from '../utils/getAnalyticsParams';
import { DeeplinkData } from '../types';
import useLoadAppsFlyer from './useLoadAppsFlyer';

type AppsFlyerDeepLinkParams = {
  isEnabled: boolean;
  isOpen?: boolean;
  deeplinkData?: DeeplinkData;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const oneLinkFallback = 'https://alltrails.onelink.me/EFvD/hvsmar15';

const useAppsFlyerDeeplink = ({ isEnabled, isOpen, deeplinkData, promptType, displayTrigger }: AppsFlyerDeepLinkParams) => {
  const apexDomain = useApexDomain();
  const browserName = useSelector((state: { browser?: UserAgentBrowser }) => state.browser?.name);
  const isAFLoaded = useLoadAppsFlyer(isEnabled);
  const experiments = useExperiments();
  const { getValue: getSessionAttribution } = useSessionStorage('sessionAttribution');

  const getDeeplink = useCallback(() => {
    if (!isEnabled || !isAFLoaded) {
      return oneLinkFallback;
    }

    let appPath = 'screen/explore';

    if (deeplinkData) {
      const { pageType, pageId } = deeplinkData;
      appPath = `${pageType}/${pageId}`;
    }

    const analyticsParams = getAnalyticsParams({ promptType, browserName, displayTrigger, experiments });
    const linkConfig = getAFOneLinkConfig({
      apexDomain,
      deepLinkPath: appPath,
      analyticsParams,
      sessionAttribution: getSessionAttribution()
    });

    const resultUrl = window.AF_SMART_SCRIPT.generateOneLinkURL(linkConfig);
    return resultUrl?.clickURL || oneLinkFallback;
  }, [isEnabled, isAFLoaded, deeplinkData, promptType, browserName, displayTrigger, experiments, apexDomain, getSessionAttribution]);

  // Impressions should be triggered after a timeout, according to the AppsFlyer documentation:
  // https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#fire-an-impression
  useEffect(() => {
    if (isEnabled && isAFLoaded && isOpen) {
      setTimeout(() => {
        window.AF_SMART_SCRIPT.fireImpressionsLink();
      }, 1000);
    }
  }, [isAFLoaded, isEnabled, isOpen]);

  return getDeeplink;
};

export default useAppsFlyerDeeplink;
