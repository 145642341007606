import Icon from '../components/Icon';

const Camping = Icon(
  'camping',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.06 18.94H23v-.01a.75.75 0 0 1 0 1.5H1a.75.75 0 1 1 0-1.5h2.08l8.05-12.36L8.79 3A.75.75 0 0 1 9 2a.76.76 0 0 1 1.13.29l2 3 2-3a.76.76 0 0 1 1-.22.75.75 0 0 1 .21 1L13 6.64l8.06 12.3Zm-1.79 0L12.07 8l-7.2 10.94h6.45v-7.6a.75.75 0 0 1 1.39-.4l5 8h1.56Zm-6.45 0h3.12L12.82 14v4.94Z"
    />
    <path d="M5 21.94h14a.75.75 0 0 1 0 1.5H5a.75.75 0 1 1 0-1.5Z" />
  </>
);

export default Camping;
