import Place from '@alltrails/denali/icons/Place';
import Waterfall from '@alltrails/denali/icons/Waterfall';
import Mountain from '@alltrails/denali/icons/PlaceMountain';
import WildFlowers from '@alltrails/denali/icons/Wildflowers';
import BirdWatching from '@alltrails/denali/icons/BirdWatching';
import Wildlife from '@alltrails/denali/icons/Wildlife';
import Beach from '@alltrails/denali/icons/Beach';
import Lake from '@alltrails/denali/icons/Lake';
import HotSpring from '@alltrails/denali/icons/HotSpring';
import Views from '@alltrails/denali/icons/Views';
import River from '@alltrails/denali/icons/River';
import Cave from '@alltrails/denali/icons/Cave';
import HistoricSite from '@alltrails/denali/icons/HistoricSite';
import Camping from '@alltrails/denali/icons/Camping';
import { IconComponent } from '@alltrails/denali/components/Icon';
import { POISubType } from '../types/algoliaResultTypes';

const getPOIIcon = (subtype: POISubType): IconComponent => {
  switch (subtype) {
    case 'peak':
      return Mountain;
    case 'waterfall':
      return Waterfall;
    case 'garden':
      return WildFlowers;
    case 'bird_hide':
      return BirdWatching;
    case 'wildlife_hide':
      return Wildlife;
    case 'bay':
    case 'beach':
      return Beach;
    case 'lake':
      return Lake;
    case 'hot_spring':
      return HotSpring;
    case 'viewpoint':
      return Views;
    case 'river':
      return River;
    case 'cave':
      return Cave;
    case 'archaeological_site':
    case 'historic_site':
    case 'ruins':
    case 'monument':
      return HistoricSite;
    case 'campsite':
      return Camping;
    default:
      return Place;
  }
};

export default getPOIIcon;
