import { useEffect, useReducer } from 'react';

export default function useLoadAppsFlyer(isEnabled: boolean) {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    function onLoad() {
      forceUpdate();
    }

    let script: HTMLScriptElement;

    function loadAppsFlyer() {
      const src = 'https://cdn-assets.alltrails.com/assets/onelink-smart-script-latest.js';

      const existing = document.querySelector(`script[src="${src}"]`);

      if (!existing) {
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.addEventListener('load', onLoad);
        document.body.appendChild(script);
      }
    }

    if (typeof window.AF_SMART_SCRIPT === 'undefined') {
      loadAppsFlyer();
    }

    return () => {
      if (script) {
        script.removeEventListener('load', onLoad);
      }
    };
  }, [isEnabled]);

  return typeof window !== 'undefined' && typeof window.AF_SMART_SCRIPT !== 'undefined';
}
